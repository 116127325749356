import React, { Component } from 'react';
import { Row, Col } from 'antd';
import {Link} from 'react-router-dom'
import './css/footer.css'


export class Footer extends Component {
	render() {
		let Y = (new Date()).getFullYear();
		return(
			<div>
				<div style={{height:100}}></div>
				<div className="footer">
					<div className="max-width">
						<Row>
							<Col span={16}>
								<div className="coryright" style={{margin:'9px 0 4px 0'}}>

									<Link to="/"><span>首页</span></Link>
									<Link to="/terms-of-use"><span>用户协议</span></Link>
									<a href="https://www.seqmax.com" target="_blank" rel="noopener noreferrer"><span>Seqmax</span></a>
									<a href="https://www.pubvar.com" target="_blank" rel="noopener noreferrer"><span>Pubvar</span></a>
								</div>
								<div className="coryright" style={{margin:'5px 0 9px 0'}}>
									<span>Copyright © {Y} &nbsp;&nbsp; 深圳云蜂生物技术有限公司 &nbsp;&nbsp; <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">粤ICP备16015396号</a></span>
								</div>
							</Col>
							<Col span={8}>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		)
	}



}