import React from "react";
import {render} from "react-dom";
import {Main} from './route.jsx'
import './index.css'





render((<Main />), document.getElementById('root'))

