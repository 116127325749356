import React, {Component} from 'react';
import {Row,Col} from 'antd';
import {Form,Input,Button,Checkbox,message} from 'antd';
import {UserOutlined,LockOutlined} from '@ant-design/icons';
import reqwest from 'reqwest';
import 'whatwg-fetch'
import 'antd/dist/antd.css'
import '../css/login.css'
import pic from '../images/login.png';

import { Redirect,Link } from 'react-router-dom'
import {CheckLogin} from '../Common.jsx'
import {Header} from '../Header.jsx';
import {Footer} from '../Footer.jsx';

export class Login extends Component {
	constructor(props) {
	    super(props);
		let redirect_to = this.redirect();	
	    this.state = {
	    	'redirect_to': redirect_to,
	    	'loading': false,
	    }
	}
	componentDidMount() {
	    document.body.style.backgroundColor = "#EEF2F9";
	}

	handleLoginClick = (data) => {
		this.setState({
			loading:true
		})
		reqwest({
			url: '/api/login',
			method: 'POST',
			type: 'json',
			data: {email:data.email, password:data.password, remember:data.remember, 'domain': 'http://www.ccngg.cn'},
		}).then(
			(data) => {
				if(data.status === 1){
					console.log('Login success!');
					let result = CheckLogin(data.token);
					if(result !== false && result.role === 'doctor' ){
						localStorage.setItem('Authorization', data.token);
						let redirect_to = this.redirect(data.token);	
						this.setState({
							'redirect_to': redirect_to,
						})
					}else{
						console.log('身份角色错误!');
						message.error('该系统仅对医生开放');
					}
				}else{
					message.error(data.msg);
				}
				this.setState({
					'loading':false
				})
			},
			(err,data) => {
				message.error('请求失败');
			}
		);
	}

	redirect = (token = '') => {
		let check_result = CheckLogin(token);
		let redirect_to = '';
		if(check_result.role === 'doctor'){
			redirect_to = '/cases';
		}
		if(check_result.role === 'sale'){
			redirect_to = '/tests';
		}
		return redirect_to;
	}


	render() {
		if(this.state.redirect_to){
			return <Redirect to={this.state.redirect_to}/>
		}
		return (
			<div>
				<Header />
    			<div className="max-width login-center-box">
					<Row justify="center" align="middle" >
		    			<Col span={14}>
		    				<img src={pic} alt="" width="100%" />
						</Col>
		    			<Col span={3}>
						</Col>
		    			<Col span={7}>
		    				<div className="login-box">
				    			<div style={{textAlign:'center', marginBottom:40,fontSize:'25px'}}>
				    				邮箱密码登陆
								</div>
		    					<Form
							      name="normal_login"
							      className="login-form"
							      initialValues={{ remember: true }}
							      onFinish={this.handleLoginClick}
							    >
							    	<Form.Item
							    		name="email"
							    		rules={[{ required: true, message: '请输入您的邮箱!' }]}
							    		>
							        	<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="邮箱" />
							    	</Form.Item>
							    	<Form.Item
							        	name="password"
							        	rules={[{ required: true, message: '请输入您的密码!' }]}
							      		>
							        	<Input
								          	prefix={<LockOutlined className="site-form-item-icon" />}
								          	type="password"
								          	placeholder="密码"
							        	/>
							      	</Form.Item>
								    <Form.Item>
								    	<Form.Item name="remember" valuePropName="checked" noStyle>
								          <Checkbox>记住我</Checkbox>
								        </Form.Item>

								        <Link className="login-form-forgot" to="/password/reset">
								          忘记密码
								        </Link>
								    </Form.Item>

							      <Form.Item>
							        <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button">
							          登录
							        </Button>
							      </Form.Item>
							    </Form>
							</div>
		    			</Col>
			    	</Row>
			    </div>
			    <Footer />
			    
		    </div>
		)
	}
}