import React,{ Component } from "react";
import { Router,Route } from 'react-router-dom';

import {Login} from './auth/Login.jsx'
import {Logout} from './auth/Logout.jsx'
import {Sendmail} from './auth/Sendmail.jsx'
import {Reset} from './auth/Reset.jsx'

import {Setting} from './Setting.jsx'


import {Cases} from './case/Cases.jsx'
import {Case} from './case/Case.jsx'
// import {NewCase} from './case/New.jsx'
// import {EditCase} from './case/Edit.jsx'

// import {Tests} from './test/Tests'
// import {Test} from './test/Test'



import { createHashHistory } from 'history';
const history = createHashHistory();

export class Main extends Component{
	render(){
		return (
			<Router history={history}>
				
				<Route exact path="/login"  render={(props) => (<Login  />) }  />
				<Route exact path="/logout"  render={ (props) => (<Logout />) } />
				<Route exact path="/password/reset"  render={ (props) => (<Sendmail />) } />
				<Route exact path="/password/reset/:token"  render={ (props) => (<Reset {...props} />) } />

				<Route exact path="/" render={(props) => (<Cases />) }  />
				<Route exact path="/cases" render={(props) => (<Cases />) }  />
				<Route exact path="/case/:id" render={(props) => (<Case {...props} />) }  />
				{
					//<Route exact path="/tests" render={(props) => (<Tests />) }  />
					//<Route exact path="/test/:id" render={(props) => (<Test {...props} />) }  />
				}
				<Route exact path="/setting"  render={ (props) => (<Setting />) } />
			</Router>
		)
    }
}
