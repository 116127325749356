import React, {Component} from 'react';
import {Row,Col} from 'antd';
import {Form,Input,Button,message,Result} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import reqwest from 'reqwest';
import 'whatwg-fetch'
import 'antd/dist/antd.css'
import '../css/login.css'

import { Redirect,Link } from 'react-router-dom'

export class Reset extends Component {
	constructor(props) {
	    super(props);
	    let temp = props.location.search.replace('?','').split('&');
	    let search = {}
	    for (let i in temp){
	    	let a = temp[i].split('=');
	    	search[a[0]] = a[1];
	    }
	    console.log(search);


	    this.state = {
	    	'token': props.match.params.token,
	    	'email': search['email'],
	    	'send_status': false,
	    	'loading' : false,
	    }
	}
	componentDidMount() {
	    document.body.style.backgroundColor = "#FFFFFF";
	}

	handlePost = (data) => {
		this.setState({loading: true});
		reqwest({
			url: '/api/password/reset',
			method: 'POST',
			type: 'json',
			data: {token:this.state.token,email:this.state.email,password:data.password,confirm:data.confirm},
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({send_status: true,'loading': false})
				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Netword error');
			}
		);
	}

	backTo = () => {
		this.setState({'redirect' : '/'})
	}


	render() {
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		if(this.state.send_status){
			return (
				<Result
					style={{marginTop:'10%'}}
					status="success"
					title="Your password has been changed successfully!"
					subTitle="Please go back to Pubvar and login."
					extra={[
						<Button type="primary" key="back" onClick={this.backTo}> Back to Pubvar</Button>,
					]}
				/>
			)
		}
		return (
			<div>
        		<div className="login-body">
        			<div className="max-width">

						<Row justify="center" align="middle" style={{marginTop:'12%'}}>
			    			<Col span={8}>
				    			<div style={{textAlign:'center', marginBottom:40,fontSize:'35px','color':'#1890ff'}}>
									CCNGG
								</div>
		    					<Form
							      name="normal_login"
							      className="login-form"
							      initialValues={{ remember: true }}
							      onFinish={this.handlePost}
							    >
									<p style={{color:'#999',textAlign:'center'}}>Change password for {this.state.email}</p>

							    	<Form.Item
							        	name="password"
							        	hasFeedback
							        	rules={[{ required: true,min:8 }]}
							      		>
							        	<Input.Password
								          	prefix={<LockOutlined className="site-form-item-icon" />}
								          	placeholder="Password"
							        	/>
							      	</Form.Item>
							      	<Form.Item
										name="confirm"
										dependencies={['password']}
										hasFeedback
										rules={[
											{
												required: true,
												message: 'Please confirm your password!',
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject('The two passwords that you entered do not match!');
												},
											}),
										]}
									>
										<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm Password" />
									</Form.Item>
							    	

							      <Form.Item>
							        <Button loading={this.state.loading} type="primary" htmlType="submit" className="login-form-button">
							          Send password reset email
							        </Button>
							        Remember your password? <Link to="/login"> Sign in</Link>
							      </Form.Item>
							    </Form>
			    			</Col>
				    	</Row>
				    </div>
			    </div>
		    </div>
		)
	}
}