import Base64  from 'base-64';
import reqwest from 'reqwest';
import moment from 'moment';

function refresh(Authorization){
    reqwest({
        url: '/api/token/refresh',
        method: 'GET',
        headers: {
            'Authorization': Authorization,
        },
        type: 'json',
    }).then(
        (data) => {
            if(data.status === 1){
                console.log('Refresh token');
                localStorage.setItem('Authorization', data.token);
            }
        }
    );
}

function CheckLogin(Authorization = ''){
    if(!Authorization){
        Authorization = localStorage.getItem('Authorization');
    }
    if(Authorization === undefined|| Authorization === '' || Authorization === null){
        console.log('User not Login')
        return false;
    }
    let [base64header, base64payload, sign] = Authorization.split('.');
    if(!base64header || !base64payload || !sign){
        localStorage.removeItem('Authorization');
        console.log('Token format error')
        return false;
    }
    let payload = JSON.parse(Base64.decode(base64payload));
	let user_name = payload.name;
    let now =  Math.round(new Date() / 1000);
	let expired_at = payload.exp;
    let role = payload.rol;
    let host = payload.sub;

    if(expired_at < now){
        localStorage.removeItem('Authorization');
        console.log('Token had expired')
        return false;
    }

    // 检查颁发源
    if(host !== 'http://www.ccngg.cn'){
        localStorage.removeItem('Authorization');
        console.log('Token sub invalid');
        return false;
    }


    // 刷新快到期的Token
    if(expired_at - now < 60*60 ){
        refresh(Authorization);
    }

	return {'Authorization': Authorization, 'user_name': user_name, 'role': role}
}

function bytesToSize(bytes) {  
    if (bytes === 0) return '0 B';  
    var k = 1024;  
    var sizes = ['B','KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));  
    return (bytes / Math.pow(k, i)).toFixed(2) + '' + sizes[i];   
} 

function getRandomuserParams(params) {
    return {
        pageSize: params.pageSize,
        page: params.current,
            ...params,
    };
};

function autoCalculateAge(data){
    if(data === null|| data === ''){
        return '';
    }
    let t = moment(data).valueOf();
    let time = new Date();
    let timestamp = Date.parse(time); 
    timestamp = (timestamp - t)/1000;

    let n = timestamp / (60*60*24*365);
    if(n > 1){
        return [Math.ceil(n), '岁'].join('');
    }
    n = timestamp / (60*60*24*30);
    if(n > 1){
        return [Math.ceil(n), '月'].join('');
    }
    n = timestamp / (60*60*24*7);
    if(n > 1){
        return [Math.ceil(n), '周'].join('');
    }

    n = timestamp / (60*60*24*7);
    if(n > 1){
        return [Math.ceil(n), '周'].join('');
    }
    n = timestamp / (60*60*24);
    if(n > 1){
        return [Math.ceil(n), '天'].join('');
    }

    return '';
};

function ageView(data){
    if(data === null || data === undefined){
        return '';
    }
    let re = /^[0-9]+$/;
    if(re.test(data)){
        return data+'岁';
    }
    let re2 = /^m[0-9]+$/;
    if(re2.test(data)){
        return data.replace('m','')+'个月';
    }
    return data;
}

export {refresh,CheckLogin,bytesToSize,getRandomuserParams,autoCalculateAge,ageView}