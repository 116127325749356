import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {Row, Col ,Menu,Dropdown} from 'antd';
import { DownOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css'
import './css/header.css'
// import logo from './images/logo.png'



export class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...props,
		};
	}

	// componentWillReceiveProps(nextProps:any) {
	// 	this.setState({
	// 		user_name : nextProps.user_name,
	// 	})
	// }
	UNSAFE_componentWillReceiveProps(nextProps:any) {
		this.setState({
			user_name : nextProps.user_name,
			role : nextProps.role,
		})
	} 
	

	

	changeLange = () => {
		localStorage.setItem('language', 'zh');
		window.location.reload();
	}
	
	tags = () => {
		if(this.state.role === 'doctor'){
			return (
				<span>
  				<Link to="/cases" className={this.state.L1 === 'cases' ? 'active' : ''}><span className="header-list hand" >我的病例</span></Link>
  				{/*<Link to="/tests" className={this.state.L1 === 'tests' ? 'active' : ''}><span className="header-list hand" >我的检测</span></Link>*/}
  				</span>
  			)
  		}
  		if(this.state.role === 'sale'){
				return (
  				<Link to="/tests" className={this.state.L1 === 'tests' ? 'active' : ''}><span className="header-list hand" >我的检测</span></Link>
  			)
  		}
	}

	render() {
	
		const menu1 = (
			<Menu>
				<Menu.Item>
					<Link to="/setting">用户设置</Link>
				</Menu.Item>
				<Menu.Item>
					<Link to="/logout">退出登录</Link>
				</Menu.Item>
			</Menu>
		);

	  	if (this.state.token) {
		    return (
		    	<div className="header">
		    		<Row className="max-width">
				    	<Col span={16}>
				    		<Link to="/" className="logo" style={{color:'#131415'}}>
				      			CCNGG
				      		</Link>
				    	</Col>
				    	
				      	<Col span={8} className="text-right">
				      		{this.tags()}
				      		<Dropdown className="hand" overlay={menu1} trigger={['click']} >
			      				<span className="header-list" onClick={e => e.preventDefault()} >
			      					{this.state.user_name} <DownOutlined />
			      				</span>
							</Dropdown>
				      	</Col>
				    </Row>
		    	</div>
		    );
		}else{
			return (
		    	<div className="header">
		    		<Row className="max-width">
				    	<Col span={12}>
				      		<Link to="/" className="logo" style={{color:'#131415'}}>
				      			CCNGG | 神经疾病分子诊断和临床研究网络
				      		</Link>
				    	</Col>
				    	
				      	<Col span={12} className="text-right">
				      		{
				      		//<Link to="/login"><span className="header-list">Log in</span></Link>
				      		//<Link to="/apply"><span className="header-list header-border">Sign out</span></Link>
				      		}
				      	</Col>
				    </Row>
		    	</div>
		    );
		}
	}
}