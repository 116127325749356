import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {Table,message,PageHeader,Button,Modal,Form,Input,Tooltip} from 'antd';
import {ExclamationCircleOutlined,LockOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import reqwest from 'reqwest'
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin,autoCalculateAge} from '../Common.jsx'



export class Cases extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin();
		if (check_result !== false){
			token = check_result.Authorization;
	    	user_name = check_result.user_name;
	    	role = check_result.role;
		}
		this.state = {
			...props,
			'L1': 'cases',
			'token' : token,
			'user_name': user_name,
			'role': role,
			'redirect' : '',
			'list' : [],
			'loading': true,
			'pagination': {
				'pageSize': 20,
				'current' : 1,
				'total' : 0,
			},
			column : [
				{'title':'ID', 'dataIndex':'patient_sn', width:90, render:(text,record) => {
					return (
						<span>
							<Link to={`/case/${record['id']}`} target="_blank" >{text}
							{ record['status'] === 'lock' ? <Tooltip placement="top" title="该病例已经锁定"><LockOutlined  /></Tooltip> : ''}
							</Link>
						</span>
					)
				}},
				{'title':'姓名', 'dataIndex':'name', width:120, ...this.getColumnSearchProps('name')},
				{'title':'性别', 'dataIndex':'gender', width:60},
				{'title':'年龄', 'dataIndex':'age_number',width:70, render:(text,record) => {
					return autoCalculateAge(record.birthdate);
				}},
				{'title':'临床症状', 'dataIndex':'clinical_phenotypes'},
				{'title':'临床诊断', 'dataIndex':'diagnosis', width:200},
				{'title':'创建日期', 'dataIndex':'created_at',width:120, render:(text) => {
					let temp = text.split(' ');
					return temp[0];
				}},
				{'title':'操作', 'key':'action', width:50, render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.removeRecord(record)}}>删除</Button>
				}},
			],
		}
	}
	componentDidMount(){
	    document.body.style.backgroundColor = "#FFFFFF";
		if(this.state.token){
			this.fetch({...this.state.pagination});
		}
	}

	

	getRandomuserParams = (params) => {
		return {
			pageSize: params.pageSize,
			page: params.current,
			...params,
		};
	}

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	fetch = (params) => {
		reqwest({
			url: `/api/patients`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			data:this.getRandomuserParams(params),
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'loading': false,
						'list': data.results.data,
						'pagination': {
			       			'current': params.current,
						    'pageSize': params.pageSize,
			       			'total': data.results.total,
			        	}
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	// 表格发生改变时候
	handleTableChange = (pagination) => {
		this.setState({
			'loading': true,
		})
		this.fetch({
			current : pagination.current,
			pageSize: pagination.pageSize,
		});
	}

	// 新增
	openNewModal = () => {
		this.setState({
			'new_case_modal': true,
		})
	}
	closeNewModal = () => {
		this.setState({
			'new_case_modal': false,
		})
	}

	pastCase = (data) => {
		reqwest({
			url: '/api/patient',
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					let patient_id = data.result;
					message.success('创建成功');
					this.setState({
						'loading':false,
						'redirect': `/case/${patient_id}`,
					})
				}else{
					message.error(data.msg);
					this.setState({'loading':false});
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'loading':false});
			}
		);
	}
	newCase = () => {
		let that = this;
		let form = this.refs.new_case;
		let patient = form.getFieldsValue();
		if(!patient.name){
			message.error('请填写姓名');
			return false;
		}

		reqwest({
			url: '/api/patient/checkDup',
			method: 'POST',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: patient
		}).then(
			(data) => {
				if(data.status === 1){
					Modal.confirm({
						title: '该样本名称有重，您确认要创建?',
						icon: <ExclamationCircleOutlined />,
						onOk() {
							that.pastCase(patient);
						},
					});
				}else{
					this.pastCase(patient);
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'loading':false});
			}
		);

	}

	patientDelete = (id) => {
		reqwest({
			url: `/api/patient/${id}`,
			method: 'DELETE',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					message.success('删除成功');
					this.fetch({...this.state.pagination});
				}else{
					message.error(data.msg);
				}
				this.setState({
					'loading': false,
				})
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	removeRecord = (record) => {
		let that = this;
		Modal.confirm({
			title: '您确信要删除该病例?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.patientDelete(record.id);
			},
		});
	}

	// 表头筛选事件
	getColumnSearchProps = dataIndex => ({
	    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div style={{ padding: 8 }}>
		        <Input
					ref={node => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ width: 188, marginBottom: 8, display: 'block' }}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90, marginRight: 8 }}
				>
		          Search
		        </Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</div>
	    ),
	    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	  //   onFilter: (value, record) => {
	  //   	if(record[dataIndex] === null){
	  //   		return false;
	  //   	}
			// return record[dataIndex]
			// 	.toString()
			// 	.toLowerCase()
			// 	.includes(value.toLowerCase());
	  //   },
	    onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}	
		},
		render: (text,record) => {
			return this.state.searchedColumn === dataIndex ? (
				dataIndex === 'hg19_name' ? (
					<Link to={'/variant/'+text.replace(/[:/]/g,'_')} target="_blank">
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[this.state.searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
					</Link>
				) : (
					<Highlighter
						highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
						searchWords={[this.state.searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
				)
			) : ( dataIndex === 'hg19_name' ? <Link to={'/variant/'+text.replace(/[:/]/g,'_')} target="_blank">{text}</Link>  : text )
		},
	});
	// 表头发起搜索
    handleSearch = (selectedKeys, confirm, dataIndex) => {
	    confirm();
	    this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
	    });
	};
	// 重置搜索
	handleReset = clearFilters => {
		clearFilters();
		this.setState({ searchText: '' });
	};

	// 表格发生改变时候
	handleTableChange = (pagination, filters, sorter) => {
		this.setState({
			sortField: sorter.field,
			sortOrder: sorter.order,
			'filter' : filters
		})
		this.fetch({
			sortField: sorter.field,
			sortOrder: sorter.order,
			current : pagination.current,
			pageSize: pagination.pageSize,
			...filters,
		});
	};
	
	render(){
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		
		return (
			<div>
				<Header {...this.state} />
				<div className="max-width">
					<PageHeader title="我的病例" style={{padding:0,marginTop:10}} 
						extra={[
							<Button key="2" type="primary" onClick={this.openNewModal}>新建病例</Button>,
						]}
					>
						<Table 
							bordered 
							loading={this.state.loading} 
							rowKey="id" 
							size="small" 
							columns={this.state.column} 
							dataSource={this.state.list}
							onChange={this.handleTableChange}
							pagination={{  //分页
						        total: this.state.pagination.total, //数据总数量
						        pageSize: this.state.pagination.pageSize,  //显示几条一页
						        defaultPageSize: 10, //默认显示几条一页
						        showSizeChanger: true,  //是否显示可以设置几条一页的选项
						        showTotal:  () => {return '共' + this.state.pagination.total + '条记录'; }
						    }}
						/>
					</PageHeader>
				</div>
				<Modal
		        	title="新建病例"
					visible={this.state.new_case_modal}
					onCancel={this.closeNewModal}
					onOk={this.newCase}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="new_case"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 4 }}
						wrapperCol = {{ span: 20 }}
						labelAlign="left"
					>
						<Form.Item name="name" label="姓名" rules={[{ required: true }]} >
							<Input value="" />
						</Form.Item>
						
					</Form>
				</Modal>
				<Footer />
			</div>
		)
	}
}