import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'

export class Logout extends Component {
	constructor(props) {
	    super(props);
		localStorage.removeItem('Authorization');
	}
	componentDidMount() {
	    document.body.style.backgroundColor = "#FFFFFF";
	}
	render(){
		return <Redirect to="/login"/>
	}
}