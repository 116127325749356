import React, {Component} from 'react';
import {Redirect,Link} from 'react-router-dom'
import {message,Button,Form,Radio,PageHeader,Modal,Breadcrumb,Descriptions,Input,Tooltip,Select,Tag,Spin,Space,Statistic,Image,
	DatePicker,Row,Col,Table,Upload} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {Header} from '../Header.jsx'
import {Footer} from '../Footer.jsx'
import {CheckLogin,autoCalculateAge,ageView} from '../Common.jsx'
import {UploadOutlined,ExclamationCircleOutlined,PlusOutlined,LockOutlined,QuestionCircleOutlined,
} from '@ant-design/icons';
import axios from 'axios'
import reqwest from 'reqwest'
import moment from 'moment'; 
// import Zmage from 'react-zmage' 
// import Viewer from 'react-viewer'
import 'moment/locale/zh-cn';
import base from '../tools/base.js';
import '../css/case.css'
import file_icon from '../images/文件.png' ;
import BuildTitle from '../BuildTitle.jsx'


moment.locale('zh-cn');
const phen_table_column = [
	{'title':'HPO', 'dataIndex':'hpo', render:(text) => {return 'HP:'+text}},
	{'title':'名称/描述', 'dataIndex':'name_chs', render:(text,record) => {
		return (
			<div>
				<div>{record['name']} {record['name_chs']}</div>
				<div>{record['definition_chs'] ? record['definition_chs'] : record['definition']}</div>
			</div>
		)
	}},
];

const date_accuracy = (text, accuracy = null) => {
	if(!text){
		return ' '
	}
	if(text === '0000-00-00'){
		return ' '
	}
	if(accuracy){
		if(accuracy === 'y'){
			return moment(text, 'YYYY-MM-DD').format('YYYY');
		}
		if(accuracy === 'm'){
			return moment(text, 'YYYY-MM-DD').format('YYYY-MM');;
		}
	}
	return moment(text, 'YYYY-MM-DD').format('YYYY-MM-DD');
}
// const omim_table_column = [
// 	{'title':'OMIM id', 'dataIndex':'disease_id'},
// 	{'title':'疾病名称', 'dataIndex':'disease_name_chs', render:(text,record) =>{
// 		return record['disease_name_chs'] ? record['disease_name_chs'] : record['disease_name'];
// 	}},
// ];
const tags = ['核磁','CT','生化检测','免疫检测','查体','代谢检测','基因检测'];

const diagnosis_type = {
	'A1': 'A1: 由已知致病基因已知致病变异（含疑似致病变异）导致的单基因遗传病',
	'A2': 'A2: 由已知致病基因新致病变异（含疑似致病变异）导致的单基因遗传病',
	'A3': 'A3: 由已知致病的染色体微缺失微重复导致的遗传病',
	'A4': 'A4: 由一个新的染色体微缺失微重复导致的遗传病，变异能判定为致病或疑似致病',
	'A5': 'A5: 染色体病，整条或部分染色体缺失或重复，核型分析能观测到',

	'B1': 'B1: 可能是已知致病基因导致的单基因遗传病，但由于证据不足，检出的变异不能判定为致病或疑似致病',
	'B2': 'B2: 可能是染色体微缺失微重复导致的遗传病，但由于证据不足，检出的变异不能判定为致病或疑似致病',
	'B3': 'B3: 患者的临床表现和一种隐性遗传病高度吻合，但只检出一个致病变异（含疑似致病）',
	'B4': 'B4: 检出了表型相关的变异，但不能解释解释患者的临床症状（非B1、B2和B3）',

	'C': 'C: 可能是一个新疾病基因导致的单基因遗传病',

	'D1': 'D1: 未检出和患者临床表现相关的变异，但患者有典型的遗传病家族史',
	'D2': 'D2: 未检出和患者临床表现相关的变异，患者没有明确的遗传病家族史',
}

const conclusion = {
	'1' : ['阳性', 'red'],
	'2' : ['不明确', 'gray'],
	'3' : ['阴性', '#50BC80'],
	'9' : ['其他', 'gray']
};
const conclusion_tips = {
	'1' : '阳性，检测到能解释患者临床表现的致病或疑似致病变异',
	'2' : '检测到和患者表型相关的变异，但不能解释患者的临床表现',
	'3' : '未检测到和患者表型相关的变异',
	'9' : '其他'
};

export class Case extends Component {
	constructor(props) {
	    super(props);
		let token = '';
		let user_name = '';
		let role = '';
		let check_result = CheckLogin()
		if (check_result !== false){
			token = check_result.Authorization
	    	user_name = check_result.user_name
	    	role = check_result.role;
		}

		this.state = {
			'spinning' : false,
			'token' : token,
			'user_name' : user_name,
			'role': role,
			'loading' : false,
			'redirect' : '',
			'id' : props.match.params.id,
			'age_unit' : '岁',
			'patient': {},
			'media': [],
			'HPO': [],
			'doctor_list': [],
			// 表型搜索需要的字段
			'search_phen' : [],
			'selected_phen' : [],
			'selectedPhen' : [], //已经所选择的表型详细信息

			'phen_on_show': [
				{'title':'HPO', 'dataIndex':'hpo', width:'120px', render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.viewHPO(record)}}>HP:{text}</Button>
				}},
				{'title':'中文名称', 'dataIndex':'name_chs',width:'200px'},
				{'title':'英文名称', 'dataIndex':'name'},
				{'title':'中文描述', 'dataIndex':'definition_chs'},
				{'title':'操作', 'key':'action', render:(text,record) => {
					return <Button type="link" className="btn-in-table" onClick={ e => {this.removePhen(record)}}>删除</Button>
				}},
			],

			'med_column': [
				{'title':'就诊日期', 'dataIndex':'date', render:(text) => {
					return moment(text,'YYYY-MM-DD').format('YYYY-MM-DD');
				}},
				{'title':'医生', 'dataIndex':'doctor'},
				{'title':'就诊记录', 'dataIndex':'text'},
				{'title':'操作', 'key':'action', render:(text,record,index) => {
					return (
						<span>
							<Button type="link" className="btn-in-table" onClick={ e => {this.openEditMed(index)}}>编辑</Button>
							<Button type="link" className="btn-in-table" onClick={ e => {this.removeMed(index)}}>删除</Button>
						</span>
					)
				}},
			],

			// 表型搜索需要的字段
			'search_omim' : [],
			'selected_omim' : [],
			'selectedOmim' : [], //已经所选择的表型详细信息
			'causal_gene' : [],
			// 'omim_on_show':[
			// 	{'title':'遗传诊断分类', 'dataIndex':'type', render:(text) => {
			// 		return <Tooltip title={diagnosis_type[text] ? diagnosis_type[text] : text}>{text} <ExclamationCircleOutlined /></Tooltip>
			// 	}},
			// 	{'title':'疾病ID', 'dataIndex':'disease_id'},
			// 	{'title':'英文疾病名', 'dataIndex':'disease_name'},
			// 	{'title':'中文疾病名', 'dataIndex':'disease_name_chs'},
			// 	{'title':'基因或cytoband', 'dataIndex':'gene_or_cytoband'},
			// 	{'title':'遗传模式', 'dataIndex':'inheritance'},
			// 	{'title':'致病变异', 'dataIndex':'variants', render:(text,record) => {
			// 		return record['variants'].map((item,index) => {
			// 			let urlid = item.replace(/[:|/]/g, '-');
			// 			return <div key={index}><a target="_blank" rel="noopener noreferrer" href={`https://www.pubvar.com/variant/${urlid}`}>{item}</a></div>
			// 		})
			// 	}},
			// 	{'title':'操作', 'key':'action', render:(text,record) => {
			// 		return <Button type="link" className="btn-in-table" onClick={ e => {this.removeCausalGene(record)}}>删除</Button>
			// 	}},
			// ],
			// 
			test_column : [
				{'title':'检测编号', 'dataIndex':'test_sn',width:110},
				{'title':'检查项目', 'dataIndex':'product_name'},
				{'title':'时间', 'dataIndex':'created_at', width:120, render:(text,record) => {
					if(!text){
						return '';
					}
					let temp = text.split(' ');
					return temp[0];
				}},
				{'title':'报告', 'dataIndex':'file_name', render:(text,record) => {
					return <div><Button type="link" className="btn-in-table" onClick={e=> {this.downloadFile(record['id'],record['file_id'], record['file_name'])}}>{record['file_name']}</Button></div>
				}},
				{'title':'结论', 'dataIndex':'conclusion', render:(text,record) => {
					return <div>{ conclusion_tips[text] ? <span style={{color:conclusion[text][1]}}>{conclusion_tips[text]}</span> : text }</div>
				}},
				{'title':'备注', 'dataIndex':'comment'},
			],

			'media_added_tags': [],
			'default_tags': ['核磁','CT','生化检测','免疫检测','查体','代谢检测','基因检测'],
			'hpo_view': {},
		}
	}

	componentDidMount(){
	    document.body.style.backgroundColor = "#FFFFFF";
		this.fetch();
		// this.dataForCaseCreate();
	}

	dataForCaseCreate = () => {
		reqwest({
			url: `/api/mgm/dataForCaseCreate`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						'doctor_list': data.doctors,
					})
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				if(err.response === 'Unauthorized.'){
					console.log('Token invalid');
					localStorage.removeItem('Authorization');
					this.setState({
						'redirect': '/login'
					})
					message.error('登录失效');
					return false;
				}
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	fetch = (params) => {
		reqwest({
			url: `/api/patient/${this.state.id}`,
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
		}).then(
			(data) => {
				if(data.status === 1){
					let result = data.result;
					if(result['birthdate'] !== '0000-00-00' && result['birthdate'] !==  null){
						result['birthdate'] = moment(result['birthdate'],"YYYY-MM-DD");
					}else{
						result['birthdate'] = '';
					}
					result['created_at'] = moment(result['created_at'],"YYYY-MM-DD");
					for(let i in data.media){
						if(data.media[i]['tags'] === '' || data.media[i]['tags'] === null){
							data.media[i]['tags'] = [];
						}else{
							data.media[i]['tags'] = data.media[i]['tags'].split('|');
						}
					}
					this.setState({
						'patient': result,
						'doctor': data.doctor,
						'age_unit': result.age_unit,
						'HPO': data.HPO,
						'media': data.media,
						'tests': this.convert_test_data(data.tests),
						'causal_gene' : result.causal_gene ? JSON.parse(result.causal_gene) : [],
						'med_records' : result.med_records ? JSON.parse(result.med_records) : [],
					});
				}else{
					message.error(data.msg);
					this.setState({
						'loading': false,
					})
				}
			},
			(err,data) => {
				message.error('Network error');
				this.setState({'loading': false})
			}
		);
	}

	convert_test_data = (data) => {
		let results = [];
		for(let i in data){
			let item = data[i];
			if(item['reports'].length === 0){
				let temp = {
					...item,
					'file_id': '', 
					'file_name': '',
					'conclusion': '',
					'comment' : '',
					'rowSpan': null,
				}
				delete(temp['reports']);
				results.push(temp);
				continue;
			}
			let rowSpan = item['reports'].length;
			for(let j in item['reports']){
				let report = item['reports'][j];
				let temp = {
					...item,
					'file_id': report['id'], 
					'file_name': report['file_name'],
					'conclusion': report['conclusion'],
					'comment' : report['comment'],
					'rowSpan': j === '0' ? rowSpan : 0,
				}
				delete(temp['reports']);
				results.push(temp);
			}
		}
		return results;
	}

	select_search = (input, option) => {
		for(let i in option.children){
			let str = option.children[i];
			str += '';
			if(str === ''){
				continue;
			}
			if (str.indexOf(input) >= 0){
				return true;
			}
		}
		return false;
	}

	openEditModal = (key) => {
		let data = {};
		data[key] = true;
		this.setState(data);
	}
	closeEditModal = (key) => {
		if(key === 'add_causal'){
			this.refs.add_causal.setFieldsValue({'gene':'', 'inheritance': '', 'disease_id': ''});
		}
		if(key === 'edit_causal'){
			this.setState({'this_edit_causal_id': undefined});
		}
		let data = {};
		data[key] = false;
		data['search_omim'] = [];
		this.setState(data);
	}
	saveAgeUnit = (value) => {
		this.setState({'age_unit': value})
	}
	patientSave = (data, callback) => {
		let that = this;
		reqwest({
			url: `/api/patient/${this.state.id}`,
			method: 'PUT',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: data
		}).then(
			(data) => {
				if(data.status === 1){
					that.fetch();
					// that.setState(state);
					if(callback !== undefined){
						callback();
					}
					message.success('操作成功');

				}else{
					message.error(data.msg);
				}
			},
			(err,data) => {
				message.error('Network error');
			}
		);
	}
	editSave = (key) => {
		let that = this;
		let form =this.refs[key];
		if(form === undefined){
			return false;
		}
		let state = {};
		state[key] = false;
		form.validateFields().then((data) => {
			if(key === 'edit_base'){
				data['age_unit'] = that.state.age_unit;
				data['birthdate_inaccuracy'] = null;
				if(data.birthdate_y){
					data.birthdate_y =  moment(data.birthdate_y).format('YYYY');
				}
				if(!data.birthdate_y && (data.birthdate_m || data.birthdate_d )) {
					message.error('出生日期格式错误');
					return false;
				}
				if(!data.birthdate_m && data.birthdate_d){
					message.error('出生日期格式错误');
					return false;
				}
				
				if(!data.birthdate_m && !data.birthdate_d){
					data['birthdate_accuracy'] = 'y';
				}

				if(data.birthdate_m && !data.birthdate_d){
					data['birthdate_accuracy'] = 'm';
				}
				if(data.birthdate_m && data.birthdate_d){
					data['birthdate_accuracy'] = 'd';
				}

				if(!data.birthdate_m){
					data.birthdate_m = '01';
				}

				if(!data.birthdate_d){
					data.birthdate_d = '01';
				}
				data['birthdate'] = data.birthdate_y+'-'+data.birthdate_m+'-'+data.birthdate_d;
				data['created_at'] = moment(data['created_at']).format("YYYY-MM-DD");
			}
			if(key === 'edit_clinical'){
				let re = /^m?[0-9]+$/
				if((data.age_onset !== undefined && data.age_onset !== "" && data.age_onset != null ) && !re.test(data.age_onset)){
					message.error('起病年龄格式错误')
					return true;
				}
			}
			this.patientSave(data, ()=>{
				this.setState(state);
			});
		})
	}


	edit_base = (record) => {
		let data = {...record};
		if(data['birthdate'] && data['birthdate'] !== '0000-00-00'){
			data['birthdate_y'] = moment(data['birthdate'],'YYYY');
			data['birthdate_m'] = moment(data['birthdate'],'YYYY-MM-DD').format('MM');
			data['birthdate_d'] = moment(data['birthdate'],'YYYY-MM-DD').format('DD');
		}else{
			data['birthdate_y'] = null;
			data['birthdate_m'] = null;
			data['birthdate_d'] = null;
		}
		return (
			<Form
				style={{marginTop:'0px'}}
				initialValues={data}
				ref="edit_base"
				layout="horizontal"
				labelCol = {{ span: 3 }}
				wrapperCol = {{ span: 21 }}
				labelAlign="left"
			>
				<Form.Item
					name="name"
					label="姓名"
					rules={[{ required: true }]}
					>
					<Input value="" />
				</Form.Item>
				<Form.Item name="ID_number" label="身份证号码" >
					<Input value="" />
				</Form.Item>
				<Form.Item name="gender" label="性别" >
					<Radio.Group>
						<Radio value="男">男</Radio>
						<Radio value="女">女</Radio>
						<Radio value="不明确">不明确</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item  label="出生日期" >
					<Form.Item name="birthdate_y"  style={{ display: 'inline-block', width: '120px',marginBottom:0 }}>
						<DatePicker placeholder="年" format="YYYY" mode="year" picker="year" locale={zhCN.DatePicker}  />
					</Form.Item>
					<span style={{ display: 'inline-block', width: '18px', lineHeight: '32px', textAlign: 'center' }}>
				       -
				    </span>
					<Form.Item name="birthdate_m" style={{ display: 'inline-block', width: '120px' ,marginBottom:0}}>
						<Select placeholder="月">
							<Select.Option value=""></Select.Option>
							<Select.Option value="01">1月</Select.Option>
							<Select.Option value="02">2月</Select.Option>
							<Select.Option value="03">3月</Select.Option>
							<Select.Option value="04">4月</Select.Option>
							<Select.Option value="05">5月</Select.Option>
							<Select.Option value="06">6月</Select.Option>
							<Select.Option value="07">7月</Select.Option>
							<Select.Option value="08">8月</Select.Option>
							<Select.Option value="09">9月</Select.Option>
							<Select.Option value="10">10月</Select.Option>
							<Select.Option value="11">11月</Select.Option>
							<Select.Option value="12">12月</Select.Option>
						</Select>
					</Form.Item>
					<span style={{ display: 'inline-block', width: '18px', lineHeight: '32px', textAlign: 'center' }}>
				       -
				    </span>
					<Form.Item name="birthdate_d" style={{ display: 'inline-block', width: '120px',marginBottom:0 }}>
						<Input placeholder="日，格式:01"/>
					</Form.Item>
				</Form.Item>

				<Form.Item name="birthplace" label="籍贯" >
					<Select>
						{
							base.province.map((item,index) => {
								return <Select.Option key={index} value={item}>{item}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
				
				<Form.Item name="race" label="民族" >
					<Select>
						{
							base.race.map((item,index) => {
								return <Select.Option key={index} value={item}>{item}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
				<Form.Item name="country" label="国籍" >
					<Input value="中国" />
				</Form.Item>
				<Form.Item name="contact_name" label="联系人" >
					<Input value="" placeholder="如果是病人本人可不填" />
				</Form.Item>
				<Form.Item name="tel" label="固定电话" >
					<Input value="" placeholder="" />
				</Form.Item>
				<Form.Item name="mobile" label="手机号码" >
					<Input value="" placeholder="" />
				</Form.Item>
				<Form.Item name="created_at" label="创建日期" >
					<DatePicker locale={zhCN.DatePicker} />
				</Form.Item>
				<Form.Item name="address" label="地址" >
					<Input.TextArea value="" placeholder="" />
				</Form.Item>
			</Form>
		)
	}

	edit_doctor = (data) => {
		return (
			<Form
				style={{marginTop:'0px'}}
				initialValues={data}
				ref="edit_doctor"
				layout="horizontal"
				labelCol = {{ span: 3 }}
				wrapperCol = {{ span: 21 }}
				labelAlign="left"
			>
				<Form.Item name="user_id" label="医生">
					<Select
						showSearch
						placeholder="请选择一个医生"
						optionFilterProp=""
						filterOption={this.select_search}
						onChange={this.getDoctorSale}
					>
						{
							this.state.doctor_list.map((item,index) => {
								return <Select.Option key={item['id']} value={item['id']}>{item['name_chs']} {item['institution']}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
			</Form>
		)
	}

	edit_clinical = (data) => {
		return (
			<Form
				style={{marginTop:'0px'}}
				initialValues={data}
				ref="edit_clinical"
				layout="horizontal"
				labelCol = {{ span: 3 }}
				wrapperCol = {{ span: 21 }}
				labelAlign="left"
			>
				<Form.Item name="clinical_abstract" label="病历摘要" >
					<Input.TextArea value="" placeholder="填写患者的主要症状，发现的时间和发展变化的过程；填写患者各项检查的结果；填写患者治疗的情况" />
				</Form.Item>
				<Form.Item name="clinical_phenotypes" label="主要症状" >
					<Input.TextArea value="" placeholder="填写患者的主要的症状；生化、核磁、免疫等检查中的异常结果" />
				</Form.Item>
				<Form.Item name="age_onset" label="起病年龄" >
					<Input value="" placeholder="填写岁数，如果不足一岁，填写几个月（30岁填写30，6个月填写m6)" />
				</Form.Item>

				
				<Form.Item name="diagnosis" label="临床诊断" >
					<Input maxLength="20" placeholder="字数不能超过20个" />
				</Form.Item>
			</Form>
		)
	}

	edit_history = (data) => {
		return (
			<Form
				style={{marginTop:'0px'}}
				initialValues={data}
				ref="edit_history"
				layout="vertical"
				labelCol = {{ span: 12 }}
				wrapperCol = {{ span: 24 }}
				labelAlign="left"
			>
				<Form.Item name="family_history"
					label={
						<span>
							有无家族史&nbsp;
							<Tooltip title="三代以内的亲属有无患类似疾病的个体">
								<QuestionCircleOutlined />
							</Tooltip>
						</span>
					}
				>
					<Radio.Group>
						<Radio value="有">有</Radio>
						<Radio value="无">无</Radio>
						<Radio value="未知/不明确">未知/不明确</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="is_consanguineous" label="父母是否近亲结婚" >
					<Radio.Group>
						<Radio value="是">是</Radio>
						<Radio value="否">否</Radio>
						<Radio value="未知/不明确">未知/不明确</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item name="family_history_text" label="家族史详情" >
					<Input.TextArea value="" placeholder="" />
				</Form.Item>
			</Form>
		)
	}

	add_causal = () => {
		let data = {};
		let causal_gene = this.state.causal_gene;
		if(this.state.this_edit_causal_id !== undefined){
			data = {...causal_gene[this.state.this_edit_causal_id]};
			data['variants'] = data['variants'].join("\r\n");
		}
		return (
			<Form
				style={{marginTop:'0px'}}
				ref="add_causal"
				initialValues={data}
				layout="horizontal"
				labelCol = {{ span: 4 }}
				wrapperCol = {{ span: 20 }}
				labelAlign="left"
			>
				<Form.Item name="type" label="遗传诊断分类"  rules={[{ required: true}]} style={{marginBottom:'5px'}}>
					<Select placeholder="">
						{
							Object.keys(diagnosis_type).map((item,index) => {
								return <Select.Option key={index} value={item}>{diagnosis_type[item]}</Select.Option>
							})
						}
					</Select>

				</Form.Item>
				<p style={{marginBottom:'20px'}}>A1-A5是阳性诊断，B1和B2是可能阳性的诊断。对于阳性诊断，建议填写以下信息。</p>

				<Form.Item name="disease_id" label="OMIM disease ID">
					<Input placeholder="OMIM疾病编号，如果OMIM中无此疾病，填写NA" loading={this.state.omim_search_loading === true ? true : false} onBlur={this.searchOMIM} />
				</Form.Item>
				<Form.Item name="disease_name" label="英文疾病名">
					<Input placeholder="" />
				</Form.Item>
				<Form.Item name="disease_name_chs" label="中文疾病名">
					<Input placeholder="" />
				</Form.Item>

				<Form.Item name="gene_or_cytoband" label="基因或cytoBand" >
					<Input placeholder="基因或者染色体位置，如果是整条染色体，填写染色体体编号，例如chr21" />
				</Form.Item>
				
				<Form.Item name="inheritance" label="遗传模式">
					<Select placeholder="选择遗传模式">
					{ ['常染色体显性', '常染色体隐性-纯合', '常染色体隐性-复合杂合', 'X连锁显性', 'X连锁隐性', '线粒体遗传', '不明确'].map((item,index) => {
						return <Select.Option key={index} value={item}>{item}</Select.Option>
					})}
					</Select>
				</Form.Item>

				<Form.Item name="variants" label="基因变异" >
					<Input.TextArea placeholder="填写variant ID或Variant name, 建议填写variant name (hg19) 或Pubvar variant ID，一行一个变异" />
				</Form.Item>
				
			</Form>
		)
	}


	edit_med = (data = {}) => {
		if(data['date']){
			data['date'] = moment(data['date'], 'YYYY-MM-DD');
		}
		return (
			<Form
				style={{marginTop:'0px'}}
				ref="edit_med"
				initialValues={data}
				layout="horizontal"
				labelCol = {{ span: 4 }}
				wrapperCol = {{ span: 20 }}
				labelAlign="left"
			>
				<Form.Item name="date" label="就诊日期"  rules={[{ required: true}]}>
					<DatePicker locale={zhCN.DatePicker} />
				</Form.Item>

				<Form.Item name="doctor" label="医生" >
					<Input />
				</Form.Item>

				<Form.Item name="text" label="就诊记录" >
					<Input.TextArea />
				</Form.Item>
			</Form>
		)
	}
	

	// 选择表型
	openPhenModal = () => {
		this.setState({
			'search_phen': [],
			'phen_search_loading': false,
			'phen_search_modal':true
		});
	}
	closePhenModal = () => {
		this.setState({
			'search_phen': [],
			'phen_search_loading': false,
			'phen_search_modal':false
		});
	}
	searchPhe = (value) => {
		if(value === ''){
			message.error('请输入关键字再搜索');
			return false;
		}
		this.setState({'phen_search_loading':true});
		reqwest({
			url: '/api/hpo',
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {s:value}
		}).then(
			(data) => {
				if(data.status === 1){
					this.setState({
						search_phen: data.results,
						'phen_search_loading':false
					})
				}else{
					message.error(data.msg);
					this.setState({'phen_search_loading':false});
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'phen_search_loading':false});
			}
		);
	}
	phenSearchPost = () => {
		if(this.state.selected_phen.lenth === 0){
			message.warning('请选择表型');
			return false;
		}
		let new_hpo = [];
		let selectedPhen = this.state.HPO;
		let selected_id = [];
		for(let i in selectedPhen){
			let d = selectedPhen[i];
			selected_id.push(d['id']);
			new_hpo.push('HP:'+d['hpo']);
		}

		for(let i in this.state.search_phen){
			let d = this.state.search_phen[i];
			if(selected_id.indexOf(d['id']) !== -1){
				continue;
			}
			if(this.state.selected_phen.indexOf(d['id']) !== -1){
				selectedPhen.push(d);
				new_hpo.push('HP:'+d['hpo']);
			}
		}
		let data = {};
		data['HPO'] = new_hpo.join(',');
		this.patientSave(data, e => {
			this.setState({
				'phen_search_modal':false,
				'selected_phen': []
			})
		});
		
	}
	removePhen = (record) => {
		let that = this;
		let new_hpo = [];
		for(let i in this.state.HPO){
			let d = this.state.HPO[i];
			if(d['id'] === record['id']){
				continue;
			}
			new_hpo.push('HP:'+d['hpo']);
		}
		Modal.confirm({
			title: '您确信要删除该表型?',
			icon: <ExclamationCircleOutlined />,
			onOk() {
				that.patientSave({'HPO': new_hpo.join(',')});
			},
		});
	}

	// 添加找到的致病基因
	searchOMIM = (e) => {
		let value = e.target.value;
		if(value === ''){
			return false;
		}
		this.setState({'omim_search_loading':true});
		reqwest({
			url: '/api/omim',
			method: 'GET',
			headers: {
				'Authorization': this.state.token,
			},
			type: 'json',
			data: {s:value}
		}).then(
			(data) => {
				if(data.status === -1){
					message.error('您输入的基因不存在');
					return false;
				}
				if(data.status === 1){
					this.setState({
						'omim_search_loading':false,
					})
					this.refs.add_causal.setFieldsValue({
						'disease_name': data.result.disease_name,
						'disease_name_chs': data.result.disease_name_chs,
					})
				}else{
					message.error(data.msg);
					this.setState({'omim_search_loading':false});
				}
			},
			(err,data) => {
				message.error('请求失败');
				this.setState({'omim_search_loading':false});
			}
		);
	}
	addCausalGene = () => {
		let that = this;
		let form =this.refs.add_causal;
		form.validateFields().then((values) => {
			// 已经添加的疾病
			let causal_gene = that.state.causal_gene;
			let added_omim = [];
			for(let i in causal_gene){
				let d = causal_gene[i];
				added_omim.push(d['disease_id']);
			}
			let index = values['disease_id'];
			if(added_omim.indexOf(index) >= 0){
				message.error('您已经添加过该疾病');
				return false;
			}

			// if(['B4', 'D1', 'D2'].indexOf(values['type']) === -1){
			// 	if (!values['disease_id']){
			// 		message.error('请填写OMIM疾病编号')
			// 		return false;
			// 	}
			// 	if (!values['gene_or_cytoband']){
			// 		message.error('请填写基因或者染色体位置')
			// 		return false;
			// 	}
			// 	if (!values['inheritance']){
			// 		message.error('请选择遗传模式')
			// 		return false;
			// 	}
			// }

			let data = {
				'type': values['type'],
				'disease_id' : values['disease_id'],
				'disease_name' : values['disease_name'],
				'disease_name_chs' : values['disease_name_chs'],
				'gene_or_cytoband' : values['gene_or_cytoband'],
				'inheritance' : values['inheritance'],
				'variants': values['variants'],
			}

			reqwest({
				url: `/api/patient/${this.state.id}/causal`,
				method: 'POST',
				headers: {
					'Authorization': this.state.token,
				},
				type: 'json',
				data: data
			}).then(
				(data) => {
					if(data.status === 1){
						that.fetch();
						message.success('操作成功');
						that.setState({
							'selected_inheritance': '',
							'add_causal': false,
						});
					}else{
						message.error(data.msg);
					}
				},
				(err,data) => {
					message.error('Network error');
				}
			);
		})
	}

	editCausalGene = () => {
		let that = this;
		let form =this.refs.add_causal;
		let id = this.state.this_edit_causal_id;

		form.validateFields().then((values) => {
			// 已经添加的疾病
			let causal_gene = that.state.causal_gene;
			let added_omim = [];
			for(let i in causal_gene){
				i = parseInt(i);
				if(i === id){
					continue;
				}
				let d = causal_gene[i];
				added_omim.push(d['disease_id']);
			}
			let index = values['disease_id'];
			if(added_omim.indexOf(index) >= 0){
				message.error('您已经添加过该疾病');
				return false;
			}

			// if(['B4', 'D1', 'D2'].indexOf(values['type']) === -1){
			// 	if (!values['disease_id']){
			// 		message.error('请填写OMIM疾病编号')
			// 		return false;
			// 	}
			// 	if (!values['gene_or_cytoband']){
			// 		message.error('请填写基因或者染色体位置')
			// 		return false;
			// 	}
			// 	if (!values['inheritance']){
			// 		message.error('请选择遗传模式')
			// 		return false;
			// 	}
			// }

			let data = {
				'type': values['type'],
				'disease_id' : values['disease_id'],
				'disease_name' : values['disease_name'],
				'disease_name_chs' : values['disease_name_chs'],
				'gene_or_cytoband' : values['gene_or_cytoband'],
				'inheritance' : values['inheritance'],
				'variants': values['variants'],
			}

			reqwest({
				url: `/api/patient/${this.state.id}/causal/${id}`,
				method: 'PUT',
				headers: {
					'Authorization': this.state.token,
				},
				type: 'json',
				data: data
			}).then(
				(data) => {
					if(data.status === 1){
						that.fetch();
						message.success('操作成功');
						that.setState({
							'selected_inheritance': '',
							'edit_causal': false,
							'this_edit_causal_id':undefined,
						});
					}else{
						message.error(data.msg);
					}
				},
				(err,data) => {
					message.error('Network error');
				}
			);
		})
	}

	removeCausalGene = (index) => {
		let that = this ;
		Modal.confirm({
			title: '注意',
			icon: <ExclamationCircleOutlined />,
			content: '您确定删除该记录?',
			onOk() {
				let causal_gene = [...that.state.causal_gene]
				causal_gene.splice(index, 1);
				that.patientSave({'causal_gene': JSON.stringify(causal_gene)}, (e) => {
					that.setState({
						'causal_gene': causal_gene,
					});
				});
    		},
 		});
	}


	// 添加就诊记录
	addMed = () => {
		let that = this;
		let med_records = this.state.med_records;
		let form =this.refs.edit_med;
		form.validateFields().then((data) => {
			data['date'] = moment(data['date']).format('YYYY-MM-DD');

			med_records.push(data);
			that.patientSave({'med_records': JSON.stringify(med_records)}, (e) => {
				form.setFieldsValue({'date':'', 'doctor': '', 'text': ''});
				that.setState({
					'med_records': med_records,
					'add_med': false,
				});
			});

		})
	}
	// 删除就诊记录
	removeMed = (index) => {
		let that = this;
		Modal.confirm({
			title: '注意',
			icon: <ExclamationCircleOutlined />,
			content: '您确定删除该记录?',
			onOk() {
				let med_records = that.state.med_records;
				med_records.splice(index, 1);
				that.patientSave({'med_records': JSON.stringify(med_records)}, (e) => {
					that.setState({
						'med_records': med_records,
					});
				});
    		},
 		});
	}

	openEditMed = (index) => {
		this.setState({
			'this_med_index': index,
			'edit_med': true,
		})
	}

	editMed = () => {
		let that = this ;
		let index = this.state.this_med_index;
		let form =this.refs.edit_med;
		form.validateFields().then((data) => {
			let med_records = that.state.med_records;
			data['date'] = moment(data['date']).format('YYYY-MM-DD');
			med_records[index] = data;
			that.patientSave({'med_records': JSON.stringify(med_records)}, (e) => {
				form.setFieldsValue({'date':'', 'doctor': '', 'text': ''});
				that.setState({
					'med_records': med_records,
					'edit_med': false,
				});
			});

		})

	}


	// 图片或视频
	openMediaModal = () => {
		this.setState({
			'media_modal':true,
			'media_added_tags': [],
		});
	}
	closeMediaModal = () => {
		this.refs.new_media.setFieldsValue({'desc':'', 'tags':[]});
		this.setState({
			'media_modal':false,
			'tempFile':undefined,
		});
	}
	mediaSave = () =>{
		if(this.state.tempFile === undefined){
			message.error('请选择图片');
			return false;
		}

		let form =this.refs.new_media;
		form.validateFields().then((data) => {
			this.setState({'spinning': true});
			data['tags'] = this.state.media_added_tags;
			if(data['desc'] === undefined){
				data['desc'] = '';
			}
			data['test_date'] = moment(data['test_date']).format('YYYY-MM-DD');
			let temp = {
				...data,
				'file': this.state.tempFile,
			}
			this.uploadFile(temp);
			this.refs.new_media.setFieldsValue({'desc':'','title':''});
			this.setState({
				'media_modal':false,
				'tempFile':undefined,
				'spinning': false,
			})
			
		});
	}

	// 删除已经上传的图片
	removeMediaAndRemote = (index) => {
		let media = this.state.media;
		let del = media.splice(index, 1);
		let that = this;
		Modal.confirm({
			title: '注意',
			icon: <ExclamationCircleOutlined />,
			content: '您确定删除该记录?',
			onOk() {
				reqwest({
					url: `/api/patient/${that.state.id}/media`,
					method: 'DELETE',
					headers: {
						'Authorization': that.state.token,
					},
					type: 'json',
					data: {id:del[0]['id']}
				}).then(
					(data) => {
						if(data.status === 1){
							message.success('删除成功');
							that.setState({
								'media': media
							})
						}else{
							message.error(data.msg);
						}
					},
					(err,data) => {
						message.error('请求失败');
					}
				);
    		},
 		});
	}
	editMediaAndRemote = (index) => {
		let media = {...this.state.media[index]};
		let media_added_tags = [];
		for(let i in media['tags']){
			media_added_tags.push(media['tags'][i]);
		}
		let default_tags = [];
		for(let i in tags){
			if(media_added_tags.indexOf(tags[i]) >= 0){
				continue;
			}
			default_tags.push(tags[i]);
		}
		let show_sub_type = null;
		if(media['type'] === '检验检查'){
			show_sub_type = true;
		}
		if(media['test_date'] === '0000-00-00'){
			media['test_date'] = null;
		}
		if(media['test_date']){
			media['test_date'] = moment(media['test_date'],'YYYY-MM-DD');
		}
		this.setState({
			'edit_media': media,
			'edit_modal': true,
			'media_added_tags': media_added_tags,
			'default_tags': default_tags,
			'show_sub_type': show_sub_type,
		})
	}
	mediaEdit = () => {
		let edit_media = this.state.edit_media;
		let form =this.refs.edit_media;
		form.validateFields().then((data) => {
			let temp = {
				// 'title': data.title,
				'desc' : data.desc === undefined ? '' : data.desc  ,
				'tags' : this.state.media_added_tags,
				'type' : data['type'],
				'sub_type' : data['type'] === '检验检查' ?  data['sub_type'] : '',
			}
			if(data['test_date']){
				temp['test_date'] = moment(data['test_date']).format('YYYY-MM-DD');
			}
			reqwest({
				url: `/api/patient/${this.state.id}/media/${edit_media['id']}`,
				method: 'PUT',
				headers: {
					'Authorization': this.state.token,
				},
				type: 'json',
				data: temp
			}).then(
				(data) => {
					if(data.status === 1){
						this.fetch();
						this.setState({
							'edit_modal':false,
							'edit_media': {},
						})
					}else{
						message.error(data.msg);
					}
				},
				(err,data) => {
					message.error('请求失败');
				}
			);
		});
	}

	closeMediaEditModal = () => {
		this.refs.edit_media.setFieldsValue({});
		this.setState({
			'edit_media': {},
			'edit_modal': false
		})
	}
	edit_media_form = (initialValues = {}) => {
		
		return (
			<Form
				style={{marginTop:'0px'}}
				ref="edit_media"
				layout="horizontal"
				labelCol = {{ span: 4 }}
				wrapperCol = {{ span: 20 }}
				initialValues={initialValues}
				labelAlign="left"
			>
				<Row className="cell">
					<Col span="4" className="cell-title">文件</Col>
					<Col span="20" className="cell-body">
						<div style={{lineHeight:'32px'}}>{initialValues['file_name']}</div>
					</Col>
				</Row>
				

				<Form.Item name="type" label="类型" rules={[{ required: true, message:'请填写图片' }]} >
					<Select onChange={(value) => {
						if(value === '检验检查'){
							this.setState({'show_sub_type' : true});
						}else{
							this.setState({'show_sub_type' : false});
						}
					}}>
						{
							['门诊记录', '住院记录', '检验检查', '其他'].map((item,index) => {
								return <Select.Option key={index} value={item}>{item}</Select.Option>
							})
						}
					</Select>
				</Form.Item>

				<Form.Item name="sub_type" label="检验检查类型" rules={[{ required: this.state.show_sub_type }]} hidden={this.state.show_sub_type ? false : true}>
					<Select>
						{
							['血液检查', '尿液检查', '粪便检查', 'X光', 'CT', '超声', '磁共振', '心电图', '脑电图', '肌电图', '病理', '生化', '免疫', '微生物', '代谢', '基因检测', '其他'].map((item,index) => {
								return <Select.Option key={index} value={item}>{item}</Select.Option>
							})
						}
					</Select>
				</Form.Item>
				<Form.Item name="test_date" label={
					<div><Tooltip title="就诊或者检测时间，建议填写">时间 <QuestionCircleOutlined /></Tooltip></div>
				}  >
					<DatePicker locale={zhCN.DatePicker}  />
				</Form.Item>
				<Form.Item name="desc" label="备注" >
					<Input  />
				</Form.Item>
				

				<Form.Item name="tags" label="标签">
					<div style={{paddingTop:'4px'}}>
						{
							this.state.media_added_tags.map((item,index) => {
								return <Tag key={item} color="#108ee9" style={{marginBottom:'9px'}} closable onClose={() => {this.removeMediaTag(item)}} >{item}</Tag>
							})
						}
						{this.state.inputVisible && (
							<Input
								ref={this.saveInputRef}
								type="text"
								size="small"
								className="tag-input"
								onBlur={this.addMediaTag}
								onPressEnter={this.addMediaTag}
							/>
						)}
						{!this.state.inputVisible && (
							<Tag className="site-tag-plus" onClick={this.showInput}>
								<PlusOutlined /> 新增标签
							</Tag>
						)}
						
					</div>

				</Form.Item>
			</Form>
		)
	}

	// 图片预览样式
	image_card = (item, index) => {
		let is_image = /^image/;
		let is_media = /^(image|video)/;
		let file_type = '';
		if(item['file_name']){
			let temp = item['file_name'].split('.');
			file_type = temp.slice(-1).join('').toUpperCase();
		}
		return (
			<div key={item['id']} className="image-card">
				<div className="card">
					<Row gutter={16}>
						<Col span="3" >
							<div className="card-image-box">
							{
								is_media.test(item['file_type']) ? (
									is_image.test(item['file_type']) ?  
										<Image className="card-image" src={item['file_path']} alt=""  />
									:
										<video onClick={() => {this.viewMedia(index)}} className="card-image" src={item['file_path']} />
											
								) :(
									<div onClick={() => {this.viewMedia(index)}}>
										<img className="card-file" src={file_icon}  alt=""/>
										<div className="card-type-show">{file_type}</div>
									</div>
								)
									
							}
							</div>
						</Col>
						<Col span="17">
							<div className="card-title">
								<span style={{marginRight:'5px'}}>{item['type']} </span>
								<span className="card-tag" style={{'fontSize':'16px'}}>{item['sub_type']}</span>
								<span className="card-tag" style={{'fontSize':'16px'}}>{item['test_date']}</span>
							</div>
							<div >{item['file_name']}</div>
							<div className="card-tag">{item['desc']}</div>
							<div>
								{item['tags'].map((item,index) => {
									return <Tag color="#108ee9" style={{marginBottom:'4px'}} key={index}>{item}</Tag>
								})}
							</div>
							
						</Col>
						<Col span="4">
							<div className="card-option">
								<Button className="card-btn" type="link" onClick={() => {this.removeMediaAndRemote(index)}} >删除</Button>
								<Button className="card-btn" type="link" onClick={() => {this.editMediaAndRemote(index)}} >编辑</Button>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		)

	}
	uploadFile = (media) => {
		let headers = {authorization: this.state.token};
		let that = this;
		const formData = new FormData();
		async function postData() {
		    formData.append('data', media.file);
		    formData.append('id', that.state.id);
		    formData.append('title', media.title);
		    formData.append('desc', media.desc);
		    formData.append('type', media.type);
		    formData.append('file_type', media.file.type);
		    formData.append('tags', media.tags.join('|'));

		    await axios.post('/api/patient/media', formData, {headers:headers})
			.then(({ data: response }) => {
				if(response.status === 0){
					message.error(response.msg);
				}else{
					that.fetch();
				}
			}).catch(
				// onError()
			);
				
		}
		postData();
	}

	viewMedia = (index) => {
		let media = this.state.media[index];
		let is_media = /^(image|video)/;
		if(is_media.test(media['file_type'])){
			this.setState({
				'view_media': media,
				'view_modal': true
			})
		}else{
			let link = document.createElement('a');
			link.style.display = 'none';
			link.href = media['file_path'];
			// link.setAttribute('download', ``)
			document.body.appendChild(link)
        	link.click()
		}
		
	}
	viewMediaBox = (media) => {
		let is_image = /^image/;
		let is_video = /^video/;
		if(media){
			if(is_image.test(media['file_type'])){
				return <img style={{width:'100%'}} src={media['file_path']} alt="" />;
			}
			if(is_video.test(media['file_type'])){
				return <video style={{width:'100%'}} src={ media['file_path']} controls="controls" />;
			}
			return media['file_name'];
		}
		return '';
	}
	closeViewMedia = (index) => {
		this.setState({
			'view_media': null,
			'view_modal': false
		})
	}


	showInput = () => {
		this.setState({ inputVisible: true }, () => this.input.focus());
	}
	saveInputRef = input => {
    	this.input = input;
  	}

  	addMediaTag = (e) =>{
  		let tag = e.target.value;
  		let added_tags = this.state.media_added_tags;
  		if(tag === ''){
			this.setState({
	  			'inputVisible':false,
	  		})
			return false;
  		}
  		if(added_tags.indexOf(tag) >= 0){
			message.error('该标签已经添加');
			return false;
  		}
  		added_tags.push(tag);
  		this.setState({
  			'media_added_tags': added_tags,
  			'inputVisible':false,
  		})
  	}
  	addDefaultMediaTag = (tag) =>{
  		let added_tags = this.state.media_added_tags;
  		if(added_tags.indexOf(tag) >= 0){
			message.error('该标签已经添加');
			return false;
  		}
  		added_tags.push(tag);
  		let default_tags = this.state.default_tags;
  		default_tags.splice(default_tags.indexOf(tag), 1);
  		this.setState({
  			'media_added_tags': added_tags,
  			'default_tags': default_tags,
  		})
  	}

  	removeMediaTag = (tag) => {
  		let added_tags = this.state.media_added_tags;
  		let default_tags = this.state.default_tags;
  		let pos = added_tags.indexOf(tag);
  		if(pos >= 0){
  			added_tags.splice(pos, 1);
  		}

  		// 如果该tag 属于default, 那么要恢复到默认标签中
  		if(tags.indexOf(tag) >= 0){
  			default_tags.push(tag);
  		}

  		this.setState({
  			'media_added_tags': added_tags,
  			'default_tags': default_tags,
  		})
  	}

  	viewHPO = (record) => {
  		console.log(record);
  		this.setState({
  			'hpo_view': record,
  			'hpo_view_modal': true,
  		})
  	}
  	closeViewHPO = (record) => {
  		this.setState({
  			'hpo_view': {},
  			'hpo_view_modal': false,
  		})
  	}

  	downloadFile = (test_id,file_id, file_name) => {
		let headers = {Authorization: this.state.token};
		axios.get(`/api/test/${test_id}/report/${file_id}`, {headers:headers, responseType: 'blob'})
		.then(res => {
			if(res.data.status === 0){
				message.error('文件下载失败');
				return false;
			}
			let re = /pdf$/i;
			if(re.test(file_name)){
				let url = window.URL.createObjectURL(new Blob([res.data],{type: 'application/pdf;chartset=UTF-8'}));
				window.open(url);
				return true;
			}
			let url = window.URL.createObjectURL(new Blob([res.data]));
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = url;	
			link.setAttribute("download", file_name);
			document.body.appendChild(link);
			link.click();
		}).catch( () =>{
			message.error('文件下载失败');
		});
	}

	
	render() {
		if(! this.state.token){
			return <Redirect to="/login"/>
		}
		if(this.state.redirect){
			return <Redirect to={this.state.redirect}/>
		}
		
		// 遗传诊断 结论
		// A:阳性  B/C: 不明确 D: 阴性
		let level_array = {
			'A1' : 4,
			'A2' : 4,
			'A3' : 4,
			'A4' : 4,
			'A5' : 4,
			'B1' : 3,
			'B2' : 3,
			'B3' : 2,
			'B4' : 2,
			'C' :  2,
			'D1' : 1,
			'D2' : 1
		}
		let level_desc = {
			4: '阳性',
			3: '可能阳性',
			2: '不明确',
			1: '阴性'
		};
		let color = {
			4: 'red',
			3: 'red',
			2: 'gray',
			1: 'green'
		};
		let level = '';
		for (let i in this.state.causal_gene){
			let d = this.state.causal_gene[i];
			let this_level = level_array[d['type']];
			if(level === ''){
				level = this_level;
				continue;
			}
			if(this_level > level){
				level = this_level;
			}
		}
		let level_str = level !== '' ? level_desc[level] : '-';
		let level_color = level !== '' ? color[level] : '';

		const props = {
			beforeUpload: (file) => {
				this.setState({
					tempFile: file
				});
				return false;
			},
			multiple: false,
			// accept: ".png,.jpg,.jpeg,.gff,.mp4,.mov",
			fileList: this.state.tempFile ? [this.state.tempFile] : [],
			onRemove: function(){
				this.setState({'tempFile': undefined});
			}
		}
		return (
			<div>
				<Header {...this.state} L1="cases" />
				<Spin tip="Loading..." spinning={this.state.spinning}>
					<div className="max-width">
	        			<Breadcrumb style={{margin:'10px 0'}}>
	        				<Breadcrumb.Item><Link to="/cases">病例库</Link></Breadcrumb.Item>
	        				<Breadcrumb.Item>{this.state.patient['patient_sn']}</Breadcrumb.Item>
	        			</Breadcrumb>

	        			<PageHeader 
	        				className="site-page-header-responsive"
	        				title={
        						<Space>
        							<span>{this.state.patient['name']},</span>
        							<span>{this.state.patient['gender']},</span>
        							<span>{autoCalculateAge(this.state.patient['birthdate'])}</span>
        						</Space>
	        				}
	        				style={{paddingLeft:0,paddingRight:0}} 
	        				subTitle={
	        					<Space>
	        						{this.state.patient.family_history === '是' ? '有家族史' : '无家族史'}
	        						<Select value={this.state.patient.disease_status} bordered={false} 
	        							style={{
	        								'marginLeft':'16px' ,
	        								color:this.state.patient.disease_status === '1' ? 'red': 'green',
	        							}} 
	        							onChange={value => {
	        								this.patientSave({'disease_status': value});
	        							}}
	        						>
	        							<Select.Option value="1">患病</Select.Option>
	        							<Select.Option value="0">未患病</Select.Option>
	        						</Select>
	        					</Space>
	        				}
	        				extra={[
								<Button key="1" type="primary" onClick={this.openMediaModal}><PlusOutlined /> 临床资料</Button>,
								<Button key="2" type="primary" onClick={e => {this.openEditModal('add_med')}}><PlusOutlined /> 就诊记录</Button>,
								<Button key="3" type="primary" onClick={this.openPhenModal}><PlusOutlined /> HPO表型</Button>,
								<Button key="4" type="primary" ><PlusOutlined /> 分类标签</Button>,
							]}
	        			>
		        			<div className="content">
								<div className="main">
									<Descriptions column={3} layout="horizontal">
										<Descriptions.Item label="创建时间">{this.state.patient['created_at'] ? moment(this.state.patient['created_at']).format('YYYY-MM-DD') : ''}</Descriptions.Item>
										<Descriptions.Item label="最后更新时间" span={2}>{this.state.patient['updated_at'] ? moment(this.state.patient['updated_at']).format('YYYY-MM-DD')  : ''}</Descriptions.Item>

										<Descriptions.Item label="主要症状" span={3}>{this.state.patient['clinical_phenotypes']}</Descriptions.Item>
										<Descriptions.Item label="HPO表型" span={3}>
											<Space>
											{
												this.state.HPO.map((item,index) => {
													return <Tag onClick={ e => {this.viewHPO(item)}} key={index}>{item['name_chs'] ? item['name_chs'] : item['name']}</Tag>
												})
											}
											</Space>
										</Descriptions.Item>
										<Descriptions.Item label="分类标签" span={3}></Descriptions.Item>
										
									</Descriptions>
								</div>
								<div className="extra">
									<div
										style={{
											display: 'flex',
											width: 'max-content',
											justifyContent: 'flex-end',
										}}
									>
										<Statistic
											title="临床资料"
											value={this.state.media.length}
											style={{marginRight: 32,}}
											/>
										<Statistic title="遗传诊断" valueStyle={{ color: level_color }}  value={level_str} />
									</div>
								</div>
							</div>
							<div style={{borderTop: '1px solid rgba(0, 0, 0, 0.06)', height:'1px'}} />
	        			</PageHeader>

	        			<PageHeader title="基本信息" style={{paddingLeft:0,paddingRight:0}} 
	        				extra={[
								<Button key="1" type="primary" onClick={ e => {this.openEditModal('edit_base')}}>编辑</Button>,
							]}
	        			>
		        			<Descriptions size="small"  column={4}  bordered className="table_list">
		        				<Descriptions.Item label="姓名">{this.state.patient['name']} { this.state.patient['status'] === 'lock' ? <Tooltip placement="top" title="该病例已经锁定"><LockOutlined  /></Tooltip> : ''}</Descriptions.Item>
		        				<Descriptions.Item label="身份证">{this.state.patient['ID_number']} </Descriptions.Item>
		        				<Descriptions.Item label="性别">{this.state.patient['gender']} </Descriptions.Item>
		        				<Descriptions.Item label="出生日期">{ date_accuracy(this.state.patient['birthdate'], this.state.patient['birthdate_accuracy'])} </Descriptions.Item>

		        				<Descriptions.Item label="籍贯">{this.state.patient['birthplace']} </Descriptions.Item>
		        				<Descriptions.Item label="年龄">{autoCalculateAge(this.state.patient['birthdate'])} </Descriptions.Item>
		        				<Descriptions.Item label="民族">{this.state.patient.race} </Descriptions.Item>
		        				<Descriptions.Item label="国家">{this.state.patient.country} </Descriptions.Item>

		        				<Descriptions.Item label="联系人">{this.state.patient['contact_name']} </Descriptions.Item>
		        				<Descriptions.Item label="固定电话">{this.state.patient['tel']} </Descriptions.Item>
		        				<Descriptions.Item label="手机号码">{this.state.patient['mobile']} </Descriptions.Item>
		        				<Descriptions.Item label="创建日期">{this.state.patient['created_at'] ? moment(this.state.patient['created_at']).format('YYYY-MM-DD') : ''} </Descriptions.Item>

		        				<Descriptions.Item label="地址" span="4">{this.state.patient['address']} </Descriptions.Item>
		        			</Descriptions>
		        		</PageHeader>

	        			<PageHeader title="临床症状和起病年龄" style={{paddingLeft:0,paddingRight:0}}
	        				extra={[
								<Button key="1" type="primary" onClick={e => {this.openEditModal('edit_clinical')}}>编辑</Button>,
							]}
	        			>
		        			<Descriptions size="small" column={1}  bordered className="table_list">
		        				<Descriptions.Item label="病历摘要" >{this.state.patient.clinical_abstract} </Descriptions.Item>
		        				<Descriptions.Item label="主要症状" >{this.state.patient.clinical_phenotypes} </Descriptions.Item>
		        				<Descriptions.Item label="起病年龄">{ageView(this.state.patient.age_onset)} </Descriptions.Item>
		        				<Descriptions.Item label="临床诊断">{this.state.patient.diagnosis} </Descriptions.Item>
		        			</Descriptions>
		        		</PageHeader>

		        		<PageHeader title="家族史" style={{paddingLeft:0,paddingRight:0}}
							extra={[
								<Button key="1" type="primary" onClick={e => {this.openEditModal('edit_history')}}>编辑</Button>,
							]}
						>
		        			<Descriptions size="small" column={1}  bordered className="table_list3">
		        				<Descriptions.Item
		        					label={
										<span>
											有无家族史&nbsp;
											<Tooltip title="三代以内的亲属有无患类似疾病的个体">
												<QuestionCircleOutlined />
											</Tooltip>
										</span>
									}
		        				 >{this.state.patient.family_history} </Descriptions.Item>
		        				<Descriptions.Item label="父母是否近亲结婚" >{this.state.patient.is_consanguineous} </Descriptions.Item>
		        				<Descriptions.Item label="家族史详情" >{this.state.patient.family_history_text} </Descriptions.Item>
		        			</Descriptions>
		        		</PageHeader>

		        		<PageHeader title="HPO表型" style={{paddingLeft:0,paddingRight:0}}
		        			extra={[
								<Button key="1" type="primary" onClick={this.openPhenModal}>添加</Button>,
							]}
		        		>
		        			<Table
								style={{marginTop:0}}
								size="small" 
								rowKey="id"
								bordered 
								columns={this.state.phen_on_show} 
								dataSource={this.state.HPO}
								pagination={false}
								locale={{emptyText:'No Data',}}
							/>
		        		</PageHeader>

		        		<PageHeader title="检测报告" style={{paddingLeft:0,paddingRight:0}}
		        			extra={[
								<Button key="1" type="primary" onClick={this.openNewModal}>新建检测</Button>,
							]}
		        		>
		        			<Table 
								bordered 
								rowKey="file_id" 
								size="small" 
								columns={this.state.test_column} 
								dataSource={this.state.tests}
								pagination={false}
								locale={{emptyText:'No Data',}}
							/>
		        		</PageHeader>
						

		        		<PageHeader title="遗传诊断" style={{paddingLeft:0,paddingRight:0}}
		        			extra={[
								<Button key="1" type="primary" onClick={e => {this.openEditModal('add_causal')}}>添加遗传诊断</Button>,
							]}
		        		>
						{
							this.state.causal_gene.map((item,index) => {
								return (
									<div className="causal-box" key={index}>
										<Descriptions title={`${diagnosis_type[item['type']]}`} column={6} size="small"
											extra={[
												<Button key="1" type="link" onClick={ e => {this.removeCausalGene(index)}}>删除</Button>,
												<Button key="2" type="link" onClick={ e => {
													this.setState({'this_edit_causal_id' : index})
													this.openEditModal('edit_causal');
												}}>编辑</Button>
											]}
										>
											<Descriptions.Item label="基因或cytoBand" span="1"> {item['gene_or_cytoband']} </Descriptions.Item>

											<Descriptions.Item label="基因变异" span="5"> 
												<Space>
												{
													item['variants'].map((v,index2) => {
														let urlid = v.replace(/[:|/]/g, '-');
														return <a key={index2} target="_blank" rel="noopener noreferrer" href={`https://www.pubvar.com/variant/${urlid}`}>{v}</a>
													})
												}
												</Space>
											</Descriptions.Item>

											<Descriptions.Item label="OMIM disease ID" span="1"> {`${item['disease_id']}`} </Descriptions.Item>
											<Descriptions.Item label="英文疾病名" span="3"> {item['disease_name']} </Descriptions.Item>
											<Descriptions.Item label="中文疾病名" span="2"> {item['disease_name_chs']} </Descriptions.Item>

											<Descriptions.Item label="遗传模式" span="6"> {item['inheritance']} </Descriptions.Item>
										</Descriptions>
												
									</div>
								)
							})
						}
						{this.state.causal_gene.length === 0 ? <div className="causal-box"><div className="empty">No Data</div></div> : ''}
		        		</PageHeader>

		        		<PageHeader title="就诊记录" style={{paddingLeft:0,paddingRight:0}}
		        			extra={[
								<Button key="1" type="primary" onClick={e => {this.openEditModal('add_med')}}>添加就诊记录</Button>,
							]}
		        		>
		        			<Table
								style={{marginTop:0}}
								size="small" 
								rowKey="date"
								bordered 
								columns={this.state.med_column} 
								dataSource={this.state.med_records}
								pagination={false}
								locale={{emptyText:'No Data',}}
							/>
		        		</PageHeader>


		        		<PageHeader title="临床资料" style={{paddingLeft:0,paddingRight:0}}
		        			extra={[
								<Button key="1" type="primary" onClick={this.openMediaModal} > <PlusOutlined />添加临床资料</Button>,
							]}
		        		>
							{
								this.state.media.map((item,index) => {
									return this.image_card(item,index);
								})
							}
							{this.state.media.length === 0 ? <div className="causal-box"><div className="empty">No Data</div></div> : ''}
		        		</PageHeader>
					</div>
				</Spin>
				<Modal
		        	title={<BuildTitle visible={this.state.edit_base} id="edit_base3" title="编辑基本信息" />}
					visible={this.state.edit_base}
					onCancel={e =>{this.closeEditModal('edit_base')}}
					onOk={e => {this.editSave('edit_base')} }
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_base3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_base(this.state.patient)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_clinical} id="edit_clinical3" title="编辑临床诊断信息" />}
					visible={this.state.edit_clinical}
					onCancel={e =>{this.closeEditModal('edit_clinical')}}
					onOk={e => {this.editSave('edit_clinical')} }
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_clinical3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_clinical(this.state.patient)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_history} id="edit_history3" title="编辑家族史信息" />}
					visible={this.state.edit_history}
					onCancel={e =>{this.closeEditModal('edit_history')}}
					onOk={e => {this.editSave('edit_history')} }
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_history3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_history(this.state.patient)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.add_causal} id="add_causal2" title="添加遗传诊断" />}
					visible={this.state.add_causal}
					onCancel={e =>{this.closeEditModal('add_causal')}}
					onOk={this.addCausalGene}
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('add_causal2').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.add_causal()}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_causal} id="edit_causal2" title="编辑遗传诊断" />}
					visible={this.state.edit_causal}
					onCancel={e =>{this.closeEditModal('edit_causal')}}
					onOk={this.editCausalGene}
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_causal2').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.add_causal()}
				</Modal>


				<Modal
		        	title={<BuildTitle visible={this.state.add_med} id="add_med3" title="添加就诊记录" />}
					visible={this.state.add_med}
					onCancel={e =>{this.closeEditModal('add_med')}}
					onOk={this.addMed}
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('add_med3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_med()}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.edit_med} id="edit_med3" title="编辑就诊记录" />}
					visible={this.state.edit_med}
					onCancel={e =>{this.closeEditModal('edit_med')}}
					onOk={this.editMed}
					width="800px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_med3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_med(this.state.this_med_index >= 0 ? this.state.med_records[this.state.this_med_index] : {})}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.phen_search_modal} id="phen_search_modal3" title="选择表型" />}
					visible={this.state.phen_search_modal}
					width="900px"
					onCancel={this.closePhenModal}
					onOk={this.phenSearchPost}
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('phen_search_modal3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>	
					<Input.Search
						placeholder="请输入表型的中文、英文或者HPO号后按回车搜索"
						enterButton
						onSearch={value => this.searchPhe(value)}
					/>
					<Table
						style={{marginTop:10}}
						size="small" 
						rowKey="id"
						loading={this.state.phen_search_loading} 
						bordered 
						columns={phen_table_column} 
						dataSource={this.state.search_phen}
						rowSelection={{
							type: 'checkbox',
							onChange: (selectedRowKeys, selectedRows) => {
								this.setState({selected_phen:selectedRowKeys})
							}
						}}
						pagination={{  //分页
					        total: this.state.search_phen.length, //数据总数量
					        showSizeChanger: true,  //是否显示可以设置几条一页的选项
					        showTotal:  () => {return '搜索到' + this.state.search_phen.length + '条数据'; }
					    }}
					    locale={{emptyText:'No Data',}}
					/>
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.media_modal} id="media_modal3" title="添加临床资料" />}
					visible={this.state.media_modal}
					onCancel={this.closeMediaModal}
					onOk={this.mediaSave}
					width="600px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('media_modal3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Form
						style={{marginTop:'0px'}}
						ref="new_media"
						layout="horizontal"
						initialValues={{}}
						labelCol = {{ span: 5 }}
						wrapperCol = {{ span: 19 }}
						labelAlign="left"

					>
						
						<Row className="cell">
							<Col span="5" className="cell-title"><span className="required">文件</span></Col>
							<Col span="19" className="cell-body">
								<Upload {...props} >
									<Button><UploadOutlined /> 选择文件</Button>
								</Upload>
							</Col>
						</Row>

						

						<Form.Item name="type" label="类型" rules={[{ required: true }]} >
							<Select onChange={(value) => {
								if(value === '检验检查'){
									this.setState({'show_sub_type' : true});
								}else{
									this.setState({'show_sub_type' : false});
								}
							}}>
								{
									['门诊记录', '住院记录', '检验检查', '其他'].map((item,index) => {
										return <Select.Option key={index} value={item}>{item}</Select.Option>
									})
								}
							</Select>
						</Form.Item>

						<Form.Item name="sub_type" label="检验检查类型" rules={[{ required: this.state.show_sub_type }]} hidden={this.state.show_sub_type ? false : true}>
							<Select>
								{
									['血液检查', '尿液检查', '粪便检查', 'X光', 'CT', '超声', '磁共振', '心电图', '脑电图', '肌电图', '病理', '生化', '免疫', '微生物', '代谢', '基因检测', '其他'].map((item,index) => {
										return <Select.Option key={index} value={item}>{item}</Select.Option>
									})
								}
							</Select>
						</Form.Item>

						<Form.Item name="test_date" label={
							<div><Tooltip title="就诊或者检测时间，建议填写">时间 <QuestionCircleOutlined /></Tooltip></div>
						}  >
							<DatePicker locale={zhCN.DatePicker}  />
						</Form.Item>

						<Form.Item name="desc" label="备注"  >
							<Input value="" />
						</Form.Item>

						<Form.Item name="tags" label="标签">
							<div style={{paddingTop:'4px'}}>
								{
									this.state.media_added_tags.map((item,index) => {
										return <Tag key={index} color="#108ee9" style={{marginBottom:'9px'}} closable onClose={() => {this.removeMediaTag(item)}} >{item}</Tag>
									})
								}
								{this.state.inputVisible && (
									<Input
										ref={this.saveInputRef}
										type="text"
										size="small"
										className="tag-input"
										onBlur={this.addMediaTag}
										onPressEnter={this.addMediaTag}
									/>
								)}
								{!this.state.inputVisible && (
									<Tag className="site-tag-plus" onClick={this.showInput}>
										<PlusOutlined /> 新增标签
									</Tag>
								)}
							</div>

						</Form.Item>
					</Form>
				</Modal>
				<Modal
		        	title={<BuildTitle visible={this.state.edit_modal} id="edit_modal3" title="编辑临床资料" />}
					visible={this.state.edit_modal}
					onCancel={this.closeMediaEditModal}
					onOk={this.mediaEdit}
					width="720px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('edit_modal3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.edit_media_form(this.state.edit_media)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.view_media} id="view_media3" title={this.state.view_media ? this.state.view_media['title'] : '无标题'} />}
					visible={this.state.view_media}
					onCancel={this.closeViewMedia}
					footer={null}
					width="960px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('view_media3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					{this.viewMediaBox(this.state.view_media)}
				</Modal>

				<Modal
		        	title={<BuildTitle visible={this.state.hpo_view_modal} id="hpo_view_modal3" title={this.state.hpo_view ? 'HP:'+this.state.hpo_view['hpo'] : ''} />}
					visible={this.state.hpo_view_modal}
					onCancel={this.closeViewHPO}
					footer={null}
					width="720px"
					maskClosable={false}
					destroyOnClose={true}
					afterClose={() => {
			            try {
							document.getElementById('hpo_view_modal3').style.transform = "translate(0px, 0px)";
						} catch (e) {}
					}}
				>
					<Descriptions size="small" column={1}  bordered className="table_list">
        				<Descriptions.Item label="HPO ID" >{this.state.hpo_view.hpo} </Descriptions.Item>
        				<Descriptions.Item label="中文名称" >{this.state.hpo_view.name_chs} </Descriptions.Item>
        				<Descriptions.Item label="英文名称" >{this.state.hpo_view.name} </Descriptions.Item>
        				<Descriptions.Item label="中文定义" >{this.state.hpo_view.definition_chs} </Descriptions.Item>
        				<Descriptions.Item label="英文定义" >{this.state.hpo_view.definition && this.state.hpo_view.definition.replace(/"/g, '')} </Descriptions.Item>
        			</Descriptions>
				</Modal>
				<Footer />

				
			</div>
		)
	}
}